.projects-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.project-blur-1 {
  width: 15rem;
  height: 23rem;
  top: 6rem;
  left: 10rem;
}
.project-blur-2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}
.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.project {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.project:nth-child(2) {
  background: #ff5733;
  transform: scale(1.05);
}

.project:hover {
  background: var(--planCard);
  transform: scale(1.1);
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.5);
  transition-duration: 1s;
  opacity: 1.5;
}
.project > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}
.project > :nth-child(2) {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.project > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.feature > img {
  width: 1rem;
}
.projects > :nth-child(2) > svg:hover {
  fill: white;
}
.projects > .project > a {
  text-align: center;
}
.projects > .project > a > button {
  color: orange;
  border-radius: 5px;
  width: 150px;
}
.projects > .project > a > button:hover {
  color: red;
}
@media screen and (max-width: 768px) {
  .projects {
    flex-direction: column;
  }
  .projects > :nth-child(2):hover {
    background: var(--planCard);
    transform: scale(1.1);
  }
  .projects > :nth-child(2) {
    transform: none;
    background: var(--caloryCard);
  }
}
