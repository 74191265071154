.skills {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}

.skills-header {
  display: flex;
  gap: 5rem;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}
.skills-categories {
  display: flex;
  gap: 1rem;
}
.category {
  display: flex;
  flex-direction: column;
  background-color: gray;
  padding: 2rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
}
.category > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}
.category > :nth-child(2) {
  font-size: 1.5rem;
  font-weight: bold;
}
.category > :nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
}
.join-now {
  font-size: 1.5rem;
  margin-left: 45%;
}
.join-now:hover {
  cursor: pointer;
  color: red;
}
.category:hover {
  cursor: pointer;
  background: var(--orange);
  transform: scale(1);
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.5);
  transition-duration: 1s;
}

@media screen and (max-width: 768px) {
  .skills-header {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .skills-categories {
    flex-direction: column;
  }
}
